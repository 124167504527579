<template>
  <div class="theme_one_block">
    <div class="theme_one_header_block">
      <div class="theme_one_header_container">
        <div class="theme_one_header_label">
          Финансовая грамотность и как ее повысить?
        </div>
      </div>
    </div>
    <div class="theme_one_main_block">
      <div class="theme_one_main_container">
        <div class="theme_one_content_container">
          <div class="module_one_plot">
            <a href="#" class="module_one_plot_module">
              О курсе
            </a>
            <a href="#" class="module_one_plot_about">
              Модуль 1
            </a>
            <a href="#" class="module_one_plot_module">
              Модуль 2
            </a>
            <a href="#" class="module_one_plot_module">
              Модуль 3
            </a>
            <a href="#" class="module_one_plot_module">
              Итоговое тестирование
            </a>
          </div>
        </div>
        <div class="review_module_desc">
          <div class="review_module_desc_text_container">
            <div class="theme_one_desc_title">
              Отзыв по модулю 1
            </div>
            <div class="theme_one_desc_text">
              15. Пожалуйста, ответьте на следующие вопросы:
              <div>1) Что Вам понравилось во время прохождения курса?</div>
              <div>2) Что Вы бы изменили?</div>
              <div>3) Была ли предоставленный материал понятен и доступен? Если нет, то что бы Вы улучшили?</div>
            </div>
            <div class="review_list_block">
              <div class="review_list_text">
                The latest advances in information technology make people think that schools of the future will use
                computers instead of printed books. Although electronic books have not been widely accepted yet, they
                have certain advantages over traditional paper volumes. But will they be able to replace printed books?
                In my opinion, students will be widely using computers for studying in the future. To begin with,
                computers can store lots of books in their memory and modern software allows us to find quickly the
                necessary information. Besides, with the interactive programs on computers studying will be much more
                exciting. What is more, electronic books will not degrade overtime like their printed counterparts. On
                the other hand, lots of disbelievers argue that computers will not replace printed books because a
                printed book is better for human eyes than a computer screen. In addition, books are cheaper and easier
                to use since they do not need electricity or the Internet connection. I cannot agree with this because
                modern computer screens emit no radiation and allow us to read even in low light conditions. Of course
                we will have to pay for electricity but I think it will be cheaper than to pay for printed books, which
                are very expensive nowadays.
              </div>
              <div class="review_list_questions">
                <span class="review_list_questions_prev">1</span>
                <span class="review_list_questions_prev">2</span>
                <span class="review_list_questions_prev">3</span>
                <span class="review_list_questions_prev">4</span>
                <span class="review_list_questions_prev">5</span>
                <span class="review_list_questions_prev">6</span>
                <span class="review_list_questions_prev">7</span>
                <span class="review_list_questions_prev">8</span>
                <span class="review_list_questions_prev">9</span>
                <span class="review_list_questions_prev">10</span>
                <span class="review_list_questions_prev">11</span>
                <span class="review_list_questions_prev">12</span>
                <span class="review_list_questions_prev">13</span>
                <span class="review_list_questions_prev">14</span>
                <span class="review_list_questions_current">15</span>
              </div>
            </div>
            <div class="theme_one_desc_btn">
              <a href="#" class="review_desc_btn_send">Отправить отзыв</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Review"
}
</script>

<style scoped>

</style>